/* @flow */

import { store } from '@enovar/bsboard-core';

import configurations from '../config';

import epics from './epics';
import reducers from './reducers';

store.injectAsyncEpics(epics);
store.injectAsyncReducer(store, reducers);

store.ajax.add('authentication', {
  uri: 'https://eauth.enovar.app/eauth/login'
});

store.ajax.add('izicash', {
  uri: 'https://demo.izicash.app/manager/v1/api/'
});

export const { endpoint } = configurations;

store.ajax.add('bsboard', {
  uri: endpoint
});

console.log(store.ajax);

export default store;
