export default () => {
  return {
    container: {
      background: '#F5F5F5',
      overflow: 'auto',
      width: '100%',
      display: 'flex',
      alignContent: 'baseline',
      flexFlow: 'row wrap',
      padding: 15
    },
    wrapper: {
      background: '#FFFFFF',
      border: '1px solid #C9C9C9',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.10)',
      borderRadius: '3px',
      padding: 20,
      margin: 10,
      width: '100%'
    },
  };
};
