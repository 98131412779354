/* @flow */

import React, { Component } from 'react';

import { theme, NumberFormat, components } from '@enovar/bsboard-core';

import moment from 'moment';

import { Table } from '../../../components';

const { css } = theme;

const { Container } = components;

class History extends Component {
  state = {
    columns: [
      {
        header: 'Status',
        accessor: 'status',
        renderCell: d => <span>{this.statusHandler(d.status)}</span>,
        sortable: false
      },
      {
        header: 'Data de Venc.',
        accessor: 'expire',
        renderCell: d => <span>{moment(d.expire).format('DD/MM/YYYY')}</span>,
        sortable: false
      },
      {
        header: 'Valor',
        accessor: 'value',
        renderCell: d => (
          <NumberFormat
            value={d.value}
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            displayType="text"
            decimalScale={2}
            fixedDecimalScale
          />
        ),
        sortable: false
      }
    ]
  };

  statusHandler = status => {
    switch (status) {
      case 'PAID':
        return 'Pago';

      default:
        return status;
    }
  };

  render() {
    const { items, styles } = this.props;
    const { columns } = this.state;

    return (
      <Container>
        <div {...css(styles.container)}>
          <div {...css(styles.wrapper)}>
            <Table columns={columns} items={items} />
          </div>
        </div>
      </Container>
    );
  }
}

History.defaultProps = {
  styles: {}
};

export default History;
