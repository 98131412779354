/* @flow */
import { Map } from 'immutable';

import {
  BILLING_FETCH_REQUEST,
  BILLING_FETCH_FAILURE,
  BILLING_FETCH_SUCCESS
} from './action';

const initialState = Map({
  type: '',
  payload: {}
});

function reducer(state = initialState, action): any {
  const { type } = action;

  if (type === BILLING_FETCH_REQUEST) {
    return state.updateIn(['type'], () => type).setIn(['payload'], {});
  }

  if (type === BILLING_FETCH_FAILURE) {
    const { payload } = action;
    return state.updateIn(['type'], () => type).setIn(['payload'], payload);
  }

  if (type === BILLING_FETCH_SUCCESS) {
    const { payload } = action;
    return state.updateIn(['type'], () => type).setIn(['payload'], payload);
  }

  return state;
}

export default reducer;
