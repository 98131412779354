/* @flow */
/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import React, { Component } from "react";
import { compose } from "recompose";

import { Button, theme } from "@enovar/bsboard-core";

const { css, withStyles } = theme;

class Goals extends Component {
  state = {};

  render() {
    const { styles } = this.props;
    return (
      <div {...css(styles.container)}>
        <div {...css(styles.wrapper)}>
          <h3>Tipography</h3>

          <div className="code-example__example">
            <h1>h.1 Elemental heading</h1>
            <h2>h.2 Elemental heading</h2>
            <h3>h.3 Elemental heading</h3>
            <h4>h.4 Elemental heading</h4>
            <h5>h.5 Elemental heading</h5>
            <h6>h.6 Elemental heading</h6>
            <hr />
            <div className="lead">
              This is a page lead, it introduces the proceeding content.
            </div>
          </div>

          <h3>Buttons</h3>

          <div className="code-example__example">
            <div className="code-example__example-element--inline">
              <Button className="Button Button--default" size="lg">
                Large Button
              </Button>
            </div>

            <div className="code-example__example-element--inline">
              <Button className="Button Button--default">Default Button</Button>
            </div>

            <div className="code-example__example-element--inline">
              <Button className="Button Button--default" size="sm">
                Small Button
              </Button>
            </div>

            <div className="code-example__example-element--inline">
              <Button className="Button Button--default" size="xs">
                Extra Small Button
              </Button>
            </div>
          </div>

          <h3>Form</h3>

          <div className="code-example__example">
            <form className="pure-form">
              <fieldset>
                <legend>A compact inline form</legend>
                <input type="email" placeholder="Email" />
                <input type="password" placeholder="Password" />
                <label htmlFor="remember">
                  <input id="remember" type="checkbox" /> Remember me
                </label>
                <button
                  type="submit"
                  className="pure-button pure-button-primary"
                >
                  Sign in
                </button>
              </fieldset>
            </form>

            <form className="pure-form pure-form-stacked">
              <fieldset>
                <legend>A Stacked Form</legend>
                <label htmlFor="email">Email</label>
                <input id="email" type="email" placeholder="Email" />
                <span className="pure-form-message">
                  This is a required field.
                </span>
                <label htmlFor="password">Password</label>
                <input id="password" type="password" placeholder="Password" />
                <label htmlFor="state">State</label>
                <select id="state">
                  <option>AL</option>
                  <option>CA</option>
                  <option>IL</option>
                </select>
                <label htmlFor="remember" className="pure-checkbox">
                  <input id="remember" type="checkbox" /> Remember me
                </label>
                <button
                  type="submit"
                  className="pure-button pure-button-primary"
                >
                  Sign in
                </button>
              </fieldset>
            </form>

            <form className="pure-form pure-form-aligned">
              <fieldset>
                <div className="pure-control-group">
                  <label htmlFor="name">Username</label>
                  <input id="name" type="text" placeholder="Username" />
                  <span className="pure-form-message-inline">
                    This is a required field.
                  </span>
                </div>
                <div className="pure-control-group">
                  <label htmlFor="password">Password</label>
                  <input id="password" type="password" placeholder="Password" />
                </div>
                <div className="pure-control-group">
                  <label htmlFor="email">Email Address</label>
                  <input id="email" type="email" placeholder="Email Address" />
                </div>
                <div className="pure-control-group">
                  <label htmlFor="foo">Supercalifragilistic Label</label>
                  <input
                    id="foo"
                    type="text"
                    placeholder="Enter something here..."
                  />
                </div>
                <div className="pure-controls">
                  <label htmlFor="cb" className="pure-checkbox">
                    <input id="cb" type="checkbox" /> I've read the terms and
                    conditions
                  </label>
                  <button
                    type="submit"
                    className="pure-button pure-button-primary"
                  >
                    Submit
                  </button>
                </div>
              </fieldset>
            </form>

            <form className="pure-form pure-form-stacked">
              <fieldset>
                <legend>Legend</legend>
                <div className="pure-g">
                  <div className="pure-u-1 pure-u-md-1-3">
                    <label htmlFor="first-name">First Name</label>
                    <input
                      id="first-name"
                      className="pure-u-23-24"
                      type="text"
                    />
                  </div>
                  <div className="pure-u-1 pure-u-md-1-3">
                    <label htmlFor="last-name">Last Name</label>
                    <input
                      id="last-name"
                      className="pure-u-23-24"
                      type="text"
                    />
                  </div>
                  <div className="pure-u-1 pure-u-md-1-3">
                    <label htmlFor="email">E-Mail</label>
                    <input
                      id="email"
                      className="pure-u-23-24"
                      type="email"
                      required
                    />
                  </div>
                  <div className="pure-u-1 pure-u-md-1-3">
                    <label htmlFor="city">City</label>
                    <input id="city" className="pure-u-23-24" type="text" />
                  </div>
                  <div className="pure-u-1 pure-u-md-1-3">
                    <label htmlFor="state">State</label>
                    <select id="state" className="pure-input-1-2">
                      <option>AL</option>
                      <option>CA</option>
                      <option>IL</option>
                    </select>
                  </div>
                </div>
                <label htmlFor="terms" className="pure-checkbox">
                  <input id="terms" type="checkbox" /> I've read the terms and
                  conditions
                </label>
                <button
                  type="submit"
                  className="pure-button pure-button-primary"
                >
                  Submit
                </button>
              </fieldset>
            </form>
            <form className="pure-form">
              <input type="email" placeholder="Requires an email" required />
            </form>
            <form className="pure-form">
              <input
                type="text"
                placeholder="Disabled input here..."
                disabled
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Goals.defaultProps = {};

const styles = () => {
  return {
    container: {
      background: "#F5F5F5",
      overflow: "scroll",
      width: "100%"
    },
    wrapper: {
      background: "#FFFFFF",
      border: "1px solid #C9C9C9",
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.10)",
      borderRadius: "3px",
      marginBottom: "40px",
      minHeight: "300px",
      padding: 20,
      margin: 20,
      width: "calc(100% - 40px)"
    }
  };
};

export default compose(withStyles(styles))(Goals);
