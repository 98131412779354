/* @flow */
/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { combineEpics } from 'redux-observable';

import { HISTORY_FETCH_REQUEST, success } from './action';

const events = action$ =>
  action$
    .ofType(HISTORY_FETCH_REQUEST)
    .map(({ payload: { params } }) => params)
    .map(() => {
      const history = [
        { status: 'PAID', expire: '01/01/2019', value: 320.54 },
        { status: 'PAID', expire: '01/12/2018', value: 310.21 },
        { status: 'PAID', expire: '01/11/2018', value: 300.77 },
        { status: 'PAID', expire: '01/10/2018', value: 290.78 },
        { status: 'PAID', expire: '01/09/2018', value: 280.65 }
      ];

      return history;
    })
    .map(history => success(history));
// .mergeMap(params =>
//   store.ajax
//     .open('get', 'history', params)('izicash')
//     .flatMap(({ response }) =>
//       Observable.of(success(response.resource))
//     )
//     .catch(err => Observable.of(failure(err)))
// );

export default combineEpics(events);
