import * as activityIndicator from '../activityIndicatorVisibility/action';

export const BILLING_FETCH_REQUEST = 'BILLING_FETCH_REQUEST';
export const BILLING_FETCH_SUCCESS = 'BILLING_FETCH_SUCCESS';
export const BILLING_FETCH_FAILURE = 'BILLING_FETCH_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: BILLING_FETCH_SUCCESS,
      payload,
    });
    dispatch(activityIndicator.hide());
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: BILLING_FETCH_FAILURE,
      payload: resource,
    });
    dispatch(activityIndicator.hide(0));
  };
}

export function fetch(params) {
  return dispatch => {
    dispatch(activityIndicator.show());
    dispatch({
      type: BILLING_FETCH_REQUEST,
      payload: {
        params,
      },
    });
  };
}
