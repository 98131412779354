/* @flow */

export const ACTIVITY_INDICATOR_VISIBILITY = 'ACTIVITY_INDICATOR_VISIBILITY';

export function hide(delay: number = 50, next: () => void = () => {}) {
  return dispatch => {
    setTimeout(() => {
      dispatch({
        type: ACTIVITY_INDICATOR_VISIBILITY,
        payload: false,
      });
      next();
    }, delay);
  };
}

export function show() {
  return dispatch => {
    dispatch({
      type: ACTIVITY_INDICATOR_VISIBILITY,
      payload: true,
    });
  };
}

export default {
  show,
  hide,
};
