import Immutable from 'immutable';
import { compose, withPropsOnChange, lifecycle, withState } from 'recompose';
import { theme, withHeader } from '@enovar/bsboard-core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  BILLING_FETCH_SUCCESS,
  BILLING_FETCH_FAILURE,
  BILLING_FETCH_REQUEST,
  fetch
} from '../../../store/billing/action';

import styles from './Billing.Styles';
import Billing from './Billing';

const { withStyles } = theme;

function mapStateToProps(state = Map({})) {
  return {
    billing: state.getIn(['billing'])
  };
}

function receiveChanges(prevProps, { billing }) {
  if (Immutable.is(prevProps.billing, billing) === false) {
    switch (billing.getIn(['type'])) {
      case BILLING_FETCH_SUCCESS:
        return true;
      case BILLING_FETCH_FAILURE:
      case BILLING_FETCH_REQUEST:
      default:
        return false;
    }
  }
  return false;
}

function propagateStateChangeToProps({ billing, changeInfo }) {
  (() => {
    if (billing.getIn(['type']) === BILLING_FETCH_SUCCESS) {
      const value = billing.getIn(['payload']);

      changeInfo(value);
    }
  })();
}

export default compose(
  withHeader('Pagamento'),
  withState('info', 'changeInfo', []),
  withRouter,
  connect(mapStateToProps),
  lifecycle({
    componentDidMount() {
      this.props.dispatch(fetch({}));
    }
  }),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
  withStyles(styles)
)(Billing);
