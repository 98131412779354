/* @flow */

import React from 'react';
import Immutable from 'immutable';
import { Route, Switch, Redirect } from 'react-router-dom';
import { compose, withState, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import { Aside, PrivateRoute, components } from '@enovar/bsboard-core';

import store from '../../store';

import Billing from './Billing';
import Goals from './Goals';
import History from './History';
import Parameters from './Parameters';

const { Spinner } = components;

const registerApplications = store.actions.applications;

export const subjects = [
  {
    name: 'App',
    items: [
      {
        name: 'Pagamento',
        to: '/app/billing'
      },
      {
        name: 'Parâmetros',
        to: '/app/parameters'
      },
      {
        name: 'Metas',
        to: '/app/goals'
      }
    ]
  }
];

store.dispatch(
  registerApplications({
    app: {
      icon: 'settings',
      name: 'app',
      path: '/app'
    }
  })
);

const Overview = ({ childProps, spinning }) => {
  const path = '/app';
  const routes = () => {
    return (
      <React.Fragment>
        <Spinner spinning={spinning} />
        <Switch>
          <PrivateRoute
            path={`${path}/goals`}
            exact
            key="goals"
            component={Goals}
            props={childProps}
          />
          <PrivateRoute
            path={`${path}/billing`}
            exact
            key="billing"
            component={Billing}
            props={childProps}
          />
          <PrivateRoute
            path={`${path}/parameters`}
            exact
            key="parameters"
            component={Parameters}
            props={childProps}
          />
          <PrivateRoute
            path={`${path}/history`}
            exact
            key="history"
            component={History}
            props={childProps}
          />
          <Route
            path={`${path}`}
            render={() => <Redirect to={`${path}/billing`} />}
          />
        </Switch>
      </React.Fragment>
    );
  };

  return (
    <Route path={path} props={childProps}>
      <div className="AppSegments">
        <Aside subjects={subjects} />
        {routes()}
      </div>
    </Route>
  );
};

function mapStateToProps(state = Immutable.Map({})) {
  return {
    spinner: state.getIn(['activityIndicatorVisibility'])
  };
}

function receiveChanges(prevProps, { spinner }) {
  if (Immutable.is(prevProps.spinner, spinner) === false) return true;
  return false;
}

function propagateStateChangeToProps({
  spinner,
  changeSpinning
}) {
  changeSpinning(spinner.getIn(['payload']));
}

export default compose(
  connect(mapStateToProps),
  withState('spinning', 'changeSpinning', false),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps)
)(Overview);
