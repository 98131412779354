/* @flow */
/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import React, { Component } from 'react';
import { compose } from 'recompose';

import { Button, theme } from '@enovar/bsboard-core';

const { css, withStyles } = theme;

class Parameters extends Component {
  state = {};

  render() {
    const { styles } = this.props;
    return (
      <div {...css(styles.container)}>
        <div {...css(styles.wrapper)}>
          <div className="code-example__example" />
        </div>

        <div {...css(styles.wrapper)}>
          <div className="code-example__example">
            <form className="pure-form pure-form-stacked">
              <fieldset>
                <legend>Horizontal</legend>
                <label htmlFor="email">Email</label>
                <input
                  className="pure-u-23-24"
                  id="new-email"
                  autocomplete="new-password"
                  type="email"
                  placeholder="Email"
                />
                <span className="pure-form-message">
                  This is a required field.
                </span>
                <label htmlFor="password">Password</label>
                <input
                  className="pure-u-23-24"
                  id="new-password"
                  autocomplete="new-password"
                  type="password"
                  placeholder="Password"
                />
                <label htmlFor="state">State</label>
                <select className="pure-u-23-24" id="state">
                  <option>AL</option>
                  <option>CA</option>
                  <option>IL</option>
                </select>
                <label htmlFor="remember" className="pure-checkbox">
                  <input
                    className="pure-u-23-24"
                    id="remember"
                    type="checkbox"
                  />{' '}
                  Remember me
                </label>
                <Button type="primary">Sign in</Button>
              </fieldset>
            </form>
          </div>
        </div>

        <div {...css(styles.wrapper)}>
          <div className="code-example__example">
            <form className="pure-form pure-form-aligned">
              <fieldset>
                <legend>Horizontal Inline</legend>
                <div className="pure-control-group">
                  <label htmlFor="name">Username</label>
                  <input
                    autocomplete="new-password"
                    id="name"
                    type="text"
                    placeholder="Username"
                  />
                  <span className="pure-form-message-inline">
                    This is a required field.
                  </span>
                </div>
                <div className="pure-control-group">
                  <label htmlFor="password">Password</label>
                  <input
                    autocomplete="new-password"
                    id="password"
                    type="password"
                    placeholder="Password"
                  />
                </div>
                <div className="pure-control-group">
                  <label htmlFor="email">Email Address</label>
                  <input id="email" type="email" placeholder="Email Address" />
                </div>
                <div className="pure-control-group">
                  <label htmlFor="foo">Label</label>
                  <input
                    id="foo"
                    type="text"
                    placeholder="Enter something here..."
                  />
                </div>
                <div className="pure-controls">
                  <label htmlFor="cb" className="pure-checkbox">
                    <input id="cb" type="checkbox" /> I've read the terms and
                    conditions
                  </label>
                  <Button type="success">Submit</Button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Parameters.defaultProps = {};

const styles = () => {
  return {
    container: {
      background: '#F5F5F5',
      overflow: 'scroll',
      width: '100%'
    },
    wrapper: {
      background: '#FFFFFF',
      border: '1px solid #C9C9C9',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.10)',
      borderRadius: '3px',
      marginBottom: '40px',
      minHeight: '300px',
      padding: 20,
      margin: 20,
      width: 'calc(100% - 40px)'
    }
  };
};

export default compose(withStyles(styles))(Parameters);
