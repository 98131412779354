/* @flow */
/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';

import { BILLING_FETCH_REQUEST, success, failure } from './action';

import store from '../';

const events = action$ =>
  action$
    .ofType(BILLING_FETCH_REQUEST)
    .map(({ payload: { params } }) => params)
    .mergeMap(params =>
      store.ajax
        .open('get', 'billing', params)('izicash')
        .flatMap(({ response }) =>
          Observable.of(success(response.resource))
        )
        .catch(err => Observable.of(failure(err)))
    );


export default combineEpics(events);
