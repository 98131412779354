import 'react/cjs/react.production.min.js';
import 'react-dom/cjs/react-dom.production.min.js';

import React, { Component } from 'react';
import { Navigator, theme, blendColors } from '@enovar/bsboard-core';

import 'antd/dist/antd.css';

import './App.css';
import store from './store';
import screens from './navigation';

const { StyleSheet } = theme;

const colors = {
  primary: '#4581BC',
  complementary: '#e2e2e2',
  acent: '#f3993e',
  grayExtraLight: '#ededed',
  grayLight: '#c6c6c6'
};

const chartColors = [
  blendColors(colors.primary, colors.acent, 0.1),
  blendColors(colors.primary, colors.acent, 1),
  blendColors(colors.grayLight, colors.grayExtraLight, 0.3),
  blendColors(colors.primary, colors.acent, 0.8),
  blendColors(colors.primary, colors.acent, 0.5),
  blendColors(colors.grayLight, colors.grayExtraLight, 0.2),
  blendColors(colors.primary, colors.acent, 0.9),
  blendColors(colors.primary, colors.acent, 0.4),
  blendColors(colors.grayLight, colors.grayExtraLight, 0.7),
  blendColors(colors.primary, colors.acent, 0.6)
];

const breakpoints = {
  small: '@media (max-width: 639px)',
  medium: '@media (max-width: 1047px)',
  large: '@media (min-width: 1048px)'
};

const fontFamily = 'Futura, sans-serif';

StyleSheet.registerTheme({
  breakpoints,
  chartColors,
  colors,
  components: {
    Aside: {
      background: 'white',
      width: 216,
      minWidth: 216, // implica em sempre manter sua largura
      screen: {
        color: '#383838'
      },
      subject: {
        color: '#474545'
      },
      logo: {
        width: '85%',
        height: 'auto',
      }
    },
    Container: {
      background: '#F7F7F7',
      minHeight: 'calc(100vh - 64px)'
    },
    Header: {
      background: '#F7F7F7',
      height: 64
    },
    Sidebar: {
      background: colors.primary,
      width: 64,
      icon: {
        color: colors.complementary,
      }
    },
    Spinner: {
      backgroundColor: colors.primary,
    },
    input: {
      borderColor: colors.grayLight,
      borderRadius: 4,
      borderSize: 1,
      fontFamily,
      fontSize: 12,
      boxShadow: {
        color: colors.grayExtraLight,
        offset: 'inset 0 1px 3px'
      }
    },
    label: {
      color: '#949494',
      display: 'block',
      fontFamily,
      fontSize: 10 * 0.9,
      letterSpacing: '0',
      textTransform: 'uppercase'
    },
    Login: {
      box: {        
        borderRadius: '0px 0 0 0px',
        boxShadow: '0 0 0px rgba(0,0,0,.10)',
      },
      button: {
        width: '100%'
      },
      container: {
        background: '',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
      },
      center: {
        boxShadow: '0 0 6px rgba(0,0,0,.10)',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        padding: '20px',
        width: '30%',
        // height: '100%',
        marginTop: '0px',
      },
      inputWrapper: {
        borderBottom: '0px'
      },
      input: {
        borderBottom: '1px solid #CCC',
        width: '100%'
      },
      logo: {
        height: '80px',
        backgroundSize: '200px',
      },
      message:{
        display: 'none',
      }
    }
  },
  text: {
    color: '#2D3436',
    size: 12
  },
  fontFamily,
  unit: 10
});

class App extends Component {
  state = {};

  componentDidMount() {
    store.dispatch(
      store.actions.localeData({
        Login: {
          buildNumber: '',
          message: 'Bem-vindo ao Liberto.',
        },
      }),
    );
  }

  render() {
    return (
      <div className="App">
        <Navigator screens={screens} />
      </div>
    );
  }
}

export default App;
