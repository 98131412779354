/* @flow */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { theme, NumberFormat, components, Icons } from '@enovar/bsboard-core';
import moment from 'moment';

const { css } = theme;

const { Container } = components;

class Billing extends Component {
  state = {};

  statusHandler = status => {
    switch (status) {
      case 'PAID':
        return 'Pago';

      default:
        return status;
    }
  };

  render() {
    const { info, styles } = this.props;

    return (
      <Container>
        <div {...css(styles.container)}>
          <div {...css(styles.wrapper)}>
            <h3 {...css(styles.textContainer)}>
              <div {...css(styles.textWrapper)}>Estimativa</div>
            </h3>

            <h2 {...css(styles.textContainer)}>
              <div
                {...css(styles.textWrapper)}
                style={{ display: 'flex', alignItems: 'baseline' }}
              >
                <NumberFormat
                  value={info.currentPayValue || 0}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$ "
                  displayType="text"
                  decimalScale={2}
                  fixedDecimalScale
                />{' '}
                <div {...css(styles.textSmall)}>(tax inclusive)</div>
              </div>
            </h2>
            <div
              {...css(styles.infoContainer)}
              style={{
                flexFlow: 'column'
              }}
            >
              <span {...css(styles.infoWrapper)}>
                <strong>Motivo da cobrança: </strong>
                <span style={{ color: 'lightslategrey' }}>{info.reason}</span>
              </span>
              <span {...css(styles.infoWrapper)}>
                <strong>Valor médio por loja: </strong>
                <NumberFormat
                  style={{ color: 'lightslategrey' }}
                  value={info.storeAvg}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$ "
                  displayType="text"
                  decimalScale={2}
                  fixedDecimalScale
                />
              </span>
              <span {...css(styles.infoWrapper)}>
                <strong>
                  Pagamento em aberto - Venc. em{' '}
                  {moment(info.currentExpire).format('DD/MM/YYYY')}
                </strong>
              </span>
            </div>
          </div>

          <div {...css(styles.wrapper)}>
            <h3 {...css(styles.textContainer)}>
              <div {...css(styles.textWrapper)}>Detalhes</div>
            </h3>

            <div {...css(styles.textMuted)} style={{ margin: '15px 0' }}>
              Pagamento mensal
            </div>
            <div {...css(styles.textContainer)} style={{ flexFlow: 'column' }}>
              <div {...css(styles.textSmall)}>Forma de Pagamento:</div>
              <div
                {...css(styles.textWrapper)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '5px 0'
                }}
              >
                <img
                  src="/assets/Boleto.png"
                  width="10%"
                  alt="Logo"
                  style={{ marginRight: 10, marginTop: 10 }}
                />
                <span>Via boleto bancário</span>
              </div>
            </div>
          </div>

          <div {...css(styles.wrapper)}>
            <h3 {...css(styles.textContainer)}>
              <div {...css(styles.textWrapper)}>Histórico</div>
            </h3>
            {info.history ? (
              info.history.slice(0, 5).map(({ status, expire, value }) => (
                <div
                  {...css(styles.infoContainer)}
                  style={{
                    flexFlow: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'baseline'
                  }}
                >
                  <span {...css(styles.infoWrapper)}>
                    {this.statusHandler(status)}
                  </span>
                  <span {...css(styles.infoWrapper)}>
                    {moment(expire).format('DD/MM/YYYY')}
                  </span>
                  <NumberFormat
                    value={value}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="R$ "
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </div>
              ))
            ) : (
              <span>Sem histórico disponível</span>
            )}
            <Link to="/app/history">Ver mais</Link>
          </div>

          <div {...css(styles.wrapper)}>
            <h3 {...css(styles.textContainer)}>
              <div {...css(styles.textWrapper)}>Contato</div>
            </h3>
            <div {...css(styles.textContainer)}>
              <div
                {...css(styles.textWrapper)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '5px 0'
                }}
              >
                <Icons.User size={48} />
                <div style={{ display: 'flex', flexFlow: 'column' }}>
                  <h4 {...css(styles.textMuted)}>John Doe</h4>
                  <span {...css(styles.textMuted, styles.textSmall)}>
                    john.doe@gmail.com
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

Billing.defaultProps = {
  styles: {}
};

export default Billing;
