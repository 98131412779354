export default (() => {
  const configurations = {};

  switch (process.env.REACT_APP_STAGE) {
    case "production":
      return Object.assign(configurations, {
        endpoint: "/v3/"
      });
    case "developement":
    default:
      return Object.assign(configurations, {
        // endpoint: 'https://dev.liberto.enovar.app/v3/',
        endpoint: "/v3/"
      });
  }
})();
