export default () => {
  return {
    container: {
      background: '#F5F5F5',
      overflow: 'auto',
      width: '100%',
      display: 'flex',
      alignContent: 'baseline',
      flexFlow: 'row wrap',
      padding: 15
    },
    wrapper: {
      background: '#FFFFFF',
      border: '1px solid #C9C9C9',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.10)',
      borderRadius: '3px',
      minHeight: '200px',
      maxHeight: '200px',
      padding: 20,
      paddingTop: 0,
      margin: 10,
      width: 'calc(50% - 20px)'
    },
    textContainer: {
      fontStyle: 'inherit',
      lineHeight: '1.16667',
      fontWeight: '500',
      letterSpacing: '-0.01em',
      display: 'flex',
      marginBottom: '5px',
      color: '#172b4d',
      fontFamily:
        "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif"
    },
    textWrapper: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      flex: '0 1 auto'
    },
    textSmall: {
      fontSize: 'x-small',
      fontWeight: 600,
      '@media (max-width: 1024px)': {
        fontSize: 'xx-small'
      }
    },
    infoContainer: {
      display: 'flex',
      color: '#172b4d',
      fontSize: 'small'
    },
    infoWrapper: {
      marginTop: 5
    },
    textMuted: {
      color: 'lightslategrey',
      fontSize: 'small'
    }
  };
};
