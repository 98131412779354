import Immutable from 'immutable';
import { compose, withPropsOnChange, lifecycle, withState } from 'recompose';
import { theme, withHeader } from '@enovar/bsboard-core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  HISTORY_FETCH_SUCCESS,
  HISTORY_FETCH_FAILURE,
  HISTORY_FETCH_REQUEST,
  fetch
} from '../../../store/history/action';

import styles from './History.Styles';
import History from './History';

const { withStyles } = theme;

function mapStateToProps(state = Map({})) {
  return {
    historyBilling: state.getIn(['history'])
  };
}

function receiveChanges(prevProps, { historyBilling }) {
  if (Immutable.is(prevProps.historyBilling, historyBilling) === false) {
    switch (historyBilling.getIn(['type'])) {
      case HISTORY_FETCH_SUCCESS:
        return true;
      case HISTORY_FETCH_FAILURE:
      case HISTORY_FETCH_REQUEST:
      default:
        return false;
    }
  }
  return false;
}

function propagateStateChangeToProps({ historyBilling, changeHistory }) {
  (() => {
    if (historyBilling.getIn(['type']) === HISTORY_FETCH_SUCCESS) {
      const value = historyBilling.getIn(['payload']);

      changeHistory(value);
    }
  })();
}

export default compose(
  withHeader('Histórico'),
  withState('items', 'changeHistory', []),
  withRouter,
  connect(mapStateToProps),
  lifecycle({
    componentDidMount() {
      this.props.dispatch(fetch({}));
    }
  }),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
  withStyles(styles)
)(History);
