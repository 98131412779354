/* @flow */

import { Map } from 'immutable';

import { ACTIVITY_INDICATOR_VISIBILITY } from './action';

const initialState = Map({
  type: ACTIVITY_INDICATOR_VISIBILITY,
  payload: false,
});

function reducer(state = initialState, action): any {
  const { type, payload } = action;

  if (type === ACTIVITY_INDICATOR_VISIBILITY) {
    return state.updateIn(['type'], () => type).setIn(['payload'], payload);
  }

  return state;
}

export default reducer;
